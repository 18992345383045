@import './assets/scss/app.scss';

.toast-top-center {
    top: 20px !important;
}

.toast-container.toast-top-center .ngx-toastr {
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

.toast-container .ngx-toastr:hover {
    box-shadow: 0 0 12px #1b1464e7;
}

.row-custom:hover{
    background: rgba(87,91,110,0.1) !important;
    cursor: pointer;
    transition: 0.4s;
}


/* SROLLBARS */


/* width */

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #abd9f8;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #abd9f8;
}


/* SROLLBARS */



/* COLUMN 5 */

.col-xs-5,

.col-sm-5,

.col-md-5,

.col-lg-5 {

  min-height: 1px; 

  position: relative;

}

.col-xs-5 {

  float: left;

  width: 20%;

}


@media (min-width: 768px) {

    .col-sm-5 {
  
      float: left;
  
      width: 20%;
  
    }
  
  }
  
  @media (min-width: 992px) {
  
    .col-md-5 {
  
      float: left;
  
      width: 20%;
  
    }
  
  }
  
  @media (min-width: 1200px) {
  
    .col-lg-5 {
  
      float: left;
  
      width: 20%;
  
    }
  
  }


/* COLUMN 5 */


.circle {
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  font-size: 15px;
  color: #fff;
  text-align: center;
  background: #000
}

body {
  --bs-modal-width: 700px !important;
}

.row-success {
  // background-color: #00fe1512;
}

.row-warning {
  // background-color: #ddff004b;
}

.row-danger {
  // background-color: #ff00002b;
}

.bs-datepicker-body table td.week span 
{
  color: #001529 !important; 
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-head,
.bs-datepicker-head, .bs-datepicker button:active,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after,
.bs-datepicker-body table td.active-week span:hover 
{
  background-color: #001529 !important; 
}